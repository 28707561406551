import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { Exports } from '@wix/editor-platform-sdk-types';

import loyaltyPageComponent from './components/LoyaltyPage/.component.json';

const _editorReady: EditorReadyFn = async (editorSDK, _appDefinitionId, { firstInstall, initialAppData }, flowAPI) => {
  const { httpClient } = flowAPI;
  const { applicationId } = initialAppData;

  if (firstInstall) {
    const pages = await editorSDK.document.pages.data.getAll('');
    const page = pages.find(({ tpaApplicationId }) => tpaApplicationId === applicationId);
    const pageRef = { id: page?.id!, type: 'DESKTOP' } as const;

    // Navigate to newly added page
    await editorSDK.pages.navigateTo('', { pageRef });

    // Rename page to match Loyalty Program name
    try {
      const loyaltyProgramRequest = await httpClient.request(getLoyaltyProgram({}));
      const loyaltyProgram = loyaltyProgramRequest.data.loyaltyProgram;

      if (loyaltyProgram?.name) {
        await editorSDK.document.pages.rename('', {
          title: loyaltyProgram.name,
          pageRef,
        });
      }
    } catch (e) {
      flowAPI.reportError(e as Error);
    }
  }
};

const _getAppManifest: GetAppManifestFn = ({ appManifestBuilder }, editorSDK, contextParams, flowAPI) => {
  const { t } = flowAPI.translations;

  appManifestBuilder.configureWidget(loyaltyPageComponent.id, (widgetBuilder) => {
    widgetBuilder
      .gfpp()
      .set('mainAction2', {
        label: t('app.gfpp.manage-program'),
        onClick: () => {
          editorSDK.editor.openDashboardPanel('', {
            url: 'loyalty-accounts/manage/?referralInfo=loyalty-gfpp',
            closeOtherPanels: false,
          });
        },
      })
      .set('help', {
        id: '5cff2a14-bdf9-47fc-9b44-ed6a2d5d3afa',
      });
  });

  return appManifestBuilder.build();
};

export const { editorReady, getAppManifest, handleAction, onEvent } = withMembersArea(
  {
    editorReady: _editorReady,
    getAppManifest: _getAppManifest,
    handleAction: async () => {},
  },
  {
    membersAreaApps: [MA_APP_IDS.MY_REWARDS],
  },
);

export const exports: Exports = (editorSDK, { biData }) => ({
  editor: {
    // NOTE: With Blocks apps the `handleAction` is no longer called by editor platform and `withMembersArea`
    // depend on this callback to install our "My Rewards" members area page. As a workaround, we are forwarding
    // fake `handleAction` events here with `appInstalled/removeApp` below.
    // See for more details: https://wix.slack.com/archives/C64RX2LA3/p1680072471990359
    async appInstalled({ appDefinitionId }) {
      return handleAction?.(
        {
          type: 'appInstalled',
          payload: {
            appDefinitionId,
            biData,
          },
        },
        editorSDK as any,
      );
    },
    async removeApp() {
      return handleAction?.(
        {
          type: 'removeApp',
          payload: {},
        },
        editorSDK as any,
      );
    },
  },
});
